import { Injectable, OnDestroy } from '@angular/core';
import { MediaChange } from '@ngbracket/ngx-layout';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { map, shareReplay } from 'rxjs';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import {
  ButtonContainerTemplateLayouts,
  ButtonContainerTemplateModel,
  ButtonContainerTemplateSizing,
} from './button-container-template.model';

/**
 * The Default State
 */
function initialState(): ButtonContainerTemplateModel {
  return {
    preferredSingleLayout: 'column',
    preferredDualLayout: 'column',
    preferredSingleButtonSizing: undefined,
    buttonCount: 0,
    activeLayout: 'column',
    activeLayoutAlign: 'start stretch',
    activeButtonSizing: undefined,
    mediaAlias: null,
  };
}

/**
 * The Store used for a {@link ButtonContainerTemplateComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class ButtonContainerTemplateRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `button-container-template-${uniqueId()}`,
    },
    withProps<ButtonContainerTemplateModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Apply layout preference for when there is a single button.
   *
   * The default is column.
   */
  applySingleLayoutPreference(layout: ButtonContainerTemplateLayouts = 'column') {
    // the layout must match an acceptable value
    if (layout !== 'row' && layout !== 'column' && layout !== 'row-reverse') {
      // or default to a column
      layout = 'column';
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.preferredSingleLayout = layout;
      }),
    );

    // process the new state
    this.processState();
  }

  /**
   * Apply layout preference for when there is 2 buttons.
   *
   * The default is column.
   */
  applyDualLayoutPreference(layout: ButtonContainerTemplateLayouts = 'column') {
    // the layout must match an acceptable value
    if (layout !== 'row' && layout !== 'column' && layout !== 'row-reverse') {
      // or default to a column
      layout = 'column';
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.preferredDualLayout = layout;
      }),
    );

    // process the new state
    this.processState();
  }

  /**
   * Apply button sizing preference for single button containers.
   *
   * The default is null.
   */
  applySingleButtonSizingPreference(buttonSizing: ButtonContainerTemplateSizing = null) {
    // the layout must match an acceptable value
    if (buttonSizing !== 'fullSize' && buttonSizing !== 'fixedSize') {
      // or default to null
      buttonSizing = null;
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.preferredSingleButtonSizing = buttonSizing;
      }),
    );

    // process the new state
    this.processState();
  }

  /**
   * Occurs when a media change happens (responsiveness breakpoints)
   */
  applyMediaChange(change: MediaChange) {
    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.mediaAlias = change.mqAlias;
      }),
    );

    // process the new state
    this.processState();
  }

  /**
   * Occurs when there is a change in the amount of buttons within the slot.
   */
  applyButtonCount(value: number) {
    // if the value is not a number, do nothing
    if (typeof value === 'undefined') {
      return;
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.buttonCount = value;
      }),
    );

    // process the new state
    this.processState();
  }

  /**
   * Takes the current configuration and uses that information to process the state
   */
  private processState() {
    // gather the data from the store
    const {
      buttonCount,
      activeLayout,
      activeLayoutAlign,
      activeButtonSizing,
      preferredSingleLayout,
      preferredSingleButtonSizing,
      mediaAlias,
      preferredDualLayout,
    } = this.store.getValue();

    // do nothing if the button count is zero as there is nothing to do
    if (buttonCount === 0) {
      return;
    }

    let newActiveLayout = activeLayout;
    let newActiveLayoutAlign = activeLayoutAlign;
    let newActiveButtonSizing = activeButtonSizing;

    // process the logic based on button count
    switch (buttonCount) {
      // if there is more than 2 buttons, the only possible choice is a column.
      default:
        newActiveLayout = 'column';
        newActiveLayoutAlign = 'center center';
        newActiveButtonSizing = preferredSingleButtonSizing;
        // if the mediaAlias is xs and the button sizing is not fullSize then fixedSize is forced
        if (mediaAlias === 'xs') {
          newActiveButtonSizing = 'fixedSize';
        }
        break;

      // if there is one button, the preferred layout is used
      case 1:
        newActiveLayout = preferredSingleLayout;
        newActiveButtonSizing = preferredSingleButtonSizing;

        // if the preffered layout is row, or row reverse, then the alignment is specific
        if (newActiveLayout === 'row' || newActiveLayout === 'row-reverse') {
          newActiveLayoutAlign = 'start center';
        } else {
          newActiveLayoutAlign = 'center center';
        }

        // if the mediaAlias is xs then a column is forced
        if (mediaAlias === 'xs') {
          newActiveLayout = 'column';
          newActiveLayoutAlign = 'center center';
          newActiveButtonSizing = 'fixedSize';
        }
        break;

      case 2:
        newActiveButtonSizing = preferredSingleButtonSizing;
        newActiveLayout = preferredDualLayout;

        if (newActiveLayout === 'row' || newActiveLayout === 'row-reverse') {
          newActiveLayoutAlign = 'space-between center';
        }

        if (mediaAlias === 'xs') {
          newActiveLayout = 'column';
          newActiveLayoutAlign = 'center center';
          newActiveButtonSizing = 'fixedSize';
        }

        break;
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.activeLayout = newActiveLayout;
        state.activeLayoutAlign = newActiveLayoutAlign;
        state.activeButtonSizing = newActiveButtonSizing;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * The Active Layout
   */
  private _activeLayout$ = this.store.pipe(
    select((state) => state.activeLayout),
    shareReplay(1),
  );

  /**
   * The Active Layout Align
   */
  private _activeLayoutAlign$ = this.store.pipe(
    select((state) => state.activeLayoutAlign),
    shareReplay(1),
  );

  /**
   * The Active Button Sizing
   */
  private _activeButtonSizing$ = this.store.pipe(
    select((state) => state.activeButtonSizing),
    shareReplay(1),
  );

  /**
   * The Active Button Sizing
   */
  activeButtonSizing$ = this._activeButtonSizing$.pipe(
    map((buttonSizing) => {
      return {
        isFixedSize: buttonSizing === 'fixedSize',
        isFullSize: buttonSizing === 'fullSize',
      };
    }),
    shareReplay(1),
  );

  /**
   * The Template Data
   */
  templateData$ = ElfCombineQueries([this._activeLayout$, this._activeLayoutAlign$]).pipe(
    map(([activeLayout, activeLayoutAlign]) => {
      return {
        activeLayout,
        activeLayoutAlign,
      };
    }),
    shareReplay(1),
  );
}
